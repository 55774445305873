import React, {Component} from 'react';
import {Col, FlexboxGrid, Panel} from "rsuite";

// Components
import AddFormComponent from "./add-form.component";

class AddComponent extends Component {
    render() {
        return (
            <>
                <FlexboxGrid justify='center'>
                    <FlexboxGrid.Item componentClass={Col} colspan={24} sm={18} md={12} lg={8}>
                        <Panel header={<h3>Add Member</h3>} bordered>
                            <AddFormComponent />
                        </Panel>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </>
        );
    }
}

export default AddComponent;