import React, {Component} from 'react';

import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/react'
import XHRUpload from '@uppy/xhr-upload'
import AwsS3 from "@uppy/aws-s3";
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

import {
    Button,
    ButtonToolbar,
    FormControl,
    FormGroup,
    Loader,
    Schema,
    Notification,
    Form, Alert, ControlLabel
} from "rsuite";

import AuthService from '../../../services/auth.service';
import {Redirect} from "react-router-dom";

// Schema
const { StringType } = Schema.Types;

const model = Schema.Model({
    memberNo: StringType().isRequired('This field is required.'),
    password: StringType().isRequired('This field is required.')
});

class TextField extends React.PureComponent {
    render() {
        const { name, label, accepter, ...props } = this.props;
        return (
            <FormGroup>
                <FormControl name={name} accepter={accepter} {...props} />
            </FormGroup>
        );
    }
}

class AddFormComponent extends Component {
    constructor(props) {
        
        super(props);

        this.state = {
            checkTrigger: 'change',
            formValue: {},
            formError: {},
            loading: false,
            registered: false
        }

        this.fileNames = [];

        this.uppy = new Uppy({
            id: "uploadFiles",
            autoProceed: true,
            debug: true
        }).use(AwsS3, {
            id: 'AddMember',
            companionUrl: 'https://api.peai.co.za/companion'
        })
        // }).use(XHRUpload, {
        //     id: 'newMember',
        //     endpoint: 'https://api.peai.co.za/api/uppy/upload',
        //     fieldName: 'media',
        //     formData: true
        //})
        .on('complete', (res) => {
            res.successful.map((file, i) => {
                console.log(file);
                // return this.fileNames.push({name: file.response.body.filename, origin: file.response.body.file});
                return this.fileNames.push({name: file.name, url: file.uploadURL});
            });
        })

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit() {
        const { formValue } = this.state;
        if (!this.form.check()) {
            Notification['error']({
                title: "Invalid Fields",
                description: <p>Please ensure all fields are filled in correctly.</p>
            });
        }

        if (this.form.check()) {
            this.setState({
                loading: true
            });

            let data = {
                name: formValue.name,
                surname: formValue.surname,
                memberNo: formValue.memberNo,
                password: formValue.password,
                email: formValue.email,
                media: this.fileNames.map((file, i) => { return file})
            };

            AuthService.register(data)
                 .then(() => {
                         this.setState({
                             registered: true
                         });
                     },
                     error => {
                         const resMessage =
                             (error.response &&
                                 error.response.data &&
                                 error.response.data.message) ||
                             error.message ||
                             error.toString();
            
                         this.setState({
                             loading: false
                         });

                         Alert.error(resMessage);

                     });
        }
    }

    render() {
        const { formValue, registered } = this.state;
        if (registered) {
            Alert.success("Member registered successfully! Redirecting to dashboard.")
            return (
                <Redirect to="/" />
            );
        } else {
            return (
                <>
                    <Form
                        fluid
                        ref={ref => (this.form = ref)}
                        onChange={formValue => {
                            this.setState({ formValue });
                        }}
                        onCheck={formError => {
                            this.setState({ formError });
                        }}
                        formValue={formValue}
                        model={model}
                    >
                        <FormGroup>
                            <TextField name="name" placeholder="Name" type="text" />
                            <TextField name="surname" placeholder="Surname" type="text" />
                            <TextField name="memberNo" placeholder="Member Number" type="text" />
                            <TextField name="email" placeholder="Email" type="email" />
                            <TextField name="password" placeholder="Member Password" type="password" />
                            <ControlLabel>Upload Certificates: </ControlLabel>
                            <Dashboard
                                uppy={this.uppy}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ButtonToolbar>
                                <Button appearance="primary" onClick={this.handleSubmit} disabled={this.state.loading}>
                                    {this.state.loading ? (
                                        <Loader content="Adding Member..." />
                                    ) : (<span>Add Member</span>)}
                                </Button>
                                <Button href="/">
                                    Cancel
                                </Button>
                            </ButtonToolbar>
                        </FormGroup>
                    </Form>
                </>
            );
        }
    }
}

export default AddFormComponent;
