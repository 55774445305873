import React, { Component } from "react";
import { Redirect } from "react-router-dom";

// Components
import LayoutComponent from "../global/layout.component";
import NavbarComponent from "../global/navbar.component";
import HeaderComponent from "../global/header.component";
import FooterComponent from "../global/footer.component";
import ContentComponent from "../global/content.component";
import LoginComponentSub from "../subcomponents/login/login.component";

export default class LoginComponent extends Component {
    constructor(props) {
        super(props);

        // State
    }

    render() {
        return (
            <LayoutComponent>
                <HeaderComponent>
                    <NavbarComponent />
                </HeaderComponent>
                <ContentComponent>
                    <LoginComponentSub />
                </ContentComponent>
                <FooterComponent />
            </LayoutComponent>
        )
    }
}