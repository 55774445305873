import React, {Component} from 'react';
import {Footer} from "rsuite";

class FooterComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            children: props.children
        }
    }

    render() {
        const { children } = this.state;
        return (
            <>
                <Footer style={{textAlign: 'center', paddingTop: '0.75rem', bottom: '0'}}>
                    Copyright © {new Date().getFullYear()} | ™<a href="https://peai.co.za" target="_blank" rel="noreferrer">PEAI</a> | Developed by <a href="https://inventixx.com" target="_blank" rel="noreferrer">Inventixx</a>
                    {children}
                </Footer>
            </>
        );
    }
}

export default FooterComponent;