import React from 'react';
import {Button, Drawer, Modal, Table} from "rsuite";

import AuthService from '../../../../services/auth.service';

const { Cell } = Table;

const ActionCell = ({rowData, dataKey, onClick, ...props}) => {
    return (
        <>
            <Cell {...props}>
                <Button
                    appearance="link"
                    onClick={() => {
                        onClick && onClick(rowData.id);
                    }}
                    style={{ padding: '0', paddingRight: '6px'}}
                >
                    {rowData.status === 'EDIT' ? 'Save ' : 'Edit'}
                </Button>
                |
                <Button
                    appearance="link"
                    onClick={() => {
                        AuthService.deleteUser(rowData.id).then(() => {
                            return window.location.reload(false);
                        })
                    }}
                    style={{ padding: '0', paddingLeft: '6px', paddingRight: '6px'}}
                >
                    Delete
                </Button>
                |
                <Button
                    appearance="link"
                    href={'/add-certificate/' + rowData.id}
                    style={{ padding: '0', paddingLeft: '6px', paddingRight: '6px'}}
                >
                    Add Certificate
                </Button>
                |
                <Button
                    appearance="link"
                    href={'/user/' + rowData.id}
                    style={{ padding: '0', paddingLeft: '6px'}}
                >
                    View Certificates
                </Button>
            </Cell>
        </>

    )
}

export default ActionCell;