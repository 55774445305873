import React, {Component} from 'react';
import {
    Alert,
    Button,
    ButtonToolbar,
    Col,
    ControlLabel,
    FlexboxGrid,
    Form,
    FormGroup,
    Loader,
    Panel
} from "rsuite";
import {Dashboard} from "@uppy/react";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import AuthService from "../../../services/auth.service";

class AddCertificateComponent extends Component {
    constructor(props) {
        super(props);
        this.fileNames = [];
        this.originNames = [];
        this.uppy = new Uppy({
            id: "Certificates",
            autoProceed: true,
            debug: true
        }).use(AwsS3, {
            id: 'AddCertificate',
            companionUrl: 'https://api.peai.co.za/companion'
        })
            // }).use(XHRUpload, {
            //     id: 'newMember',
            //     endpoint: 'https://peai-api.herokuapp.com/api/uppy/upload',
            //     fieldName: 'media',
            //     formData: true
            //})
            .on('complete', (res) => {
                res.successful.map((file, i) => {
                    console.log(file);
                    // return this.fileNames.push({name: file.response.body.filename, origin: file.response.body.file});
                    return this.fileNames.push({name: file.name, url: file.uploadURL});
                });
            })
        this.state = {
            added: true,
            userId: props.userID
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit() {
        this.setState({
            loading: true
        });
        let data = {
            id: this.state.userId,
            media: this.fileNames.map((file, i) => { return file}),
        };
        AuthService.addCertificate(data)
            .then(() => {
                    this.setState({
                        added: true,
                        loading: false
                    });
                }).catch(error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.setState({
                        loading: false
                    });
                    Alert.error(resMessage);

        })
    }


    render() {
        return (
            <>
                <FlexboxGrid justify='center'>
                    <FlexboxGrid.Item componentClass={Col} colspan={24} sm={18} md={12} lg={8}>
                        <Panel header={<h3>Add Certificate</h3>} bordered>
                            <Form
                                fluid
                                ref={ref => (this.form = ref)}
                            >
                                <FormGroup>
                                    <ControlLabel>Upload Certificates: </ControlLabel>
                                    <Dashboard
                                        uppy={this.uppy}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <ButtonToolbar>
                                        <Button appearance="primary" onClick={this.handleSubmit} disabled={this.state.loading}>
                                            {this.state.loading ? (
                                                <Loader content="Adding Certificates..." />
                                            ) : (<span>Done</span>)}
                                        </Button>
                                        <Button href="/">
                                            Cancel
                                        </Button>
                                    </ButtonToolbar>
                                </FormGroup>
                            </Form>
                        </Panel>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </>
        );
    }
}

export default AddCertificateComponent;
