import React, {Component} from 'react';
import {
    Alert,
    Col,
    FlexboxGrid,
} from "rsuite";
import AuthService from '../../../services/auth.service';
import { Document, Page } from 'react-pdf';
import Pdf from '@mikecousins/react-pdf';

class ViewUserProfileComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: props.userID,
            certificates: []
        }
    }

    componentDidMount() {
        AuthService.getUser(this.state.userId).then((res) => {
            const certificates = res.data.certificates;
            console.log(res)
            if (certificates.length > 0) {
                this.setState({
                    certificates: certificates
                });
            }
        }).catch(err => {
            Alert.error(err.message);
        });
    }

    render() {
        const certificates = this.state.certificates;
        return (
            <>
                <FlexboxGrid justify='center'>
                    <FlexboxGrid.Item componentClass={Col} colspan={24} sm={18} md={12} lg={8}>
                        {certificates.length > 0 ? (
                            <div>
                                {certificates && certificates.map((certificate) => (
                                    <div>
                                        {/* <Document
                                            url = {certificate.url}
                                            loading="please wait loading certificates..."
                                        > 
                                        <Page pageNumber={1}/>
                                        </Document> */}
                                        {/* <Pdf file={certificate.url} page={1} /> */}
                                        <a href={certificate.url}>{certificate.name}</a>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <h2 style={{ textAlign: 'center' }}>This user has no certificates.</h2>
                        )}
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </>
        );
    }
}

export default ViewUserProfileComponent;