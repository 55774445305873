import React, { Component } from "react";

// Components
import LayoutComponent from "../global/layout.component";
import NavbarComponent from "../global/navbar.component";
import HeaderComponent from "../global/header.component";
import FooterComponent from "../global/footer.component";
import ContentComponent from "../global/content.component";
import AddNewsletterComponent from "../subcomponents/add-newsletter/add-newsletter.component";

export default class AddNewsletterPageComponent extends Component {
    constructor(props) {
        super(props);

        // State
        this.state = {
            userID: this.props.match.params.id
        }
    }

    render() {
        return (
            <LayoutComponent>
                <HeaderComponent>
                    <NavbarComponent history={this.props.history} />
                </HeaderComponent>
                <ContentComponent>
                    <AddNewsletterComponent />
                </ContentComponent>
                <FooterComponent />
            </LayoutComponent>
        )
    }
}