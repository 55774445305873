import React, { Component } from "react";
import { Navbar, Nav, Icon } from "rsuite";
import { useHistory } from "react-router-dom";

// Services
import AuthService from '../../services/auth.service';

class NavbarComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeKey: props.activeKey,
            openKey: props.openKey || '',
            currentUser: AuthService.getCurrentUser()
        }

        this.logOut = this.logOut.bind(this);
    }

    logOut() {
        AuthService.logout();
        this.setState({
            currentUser: undefined
        });
        this.props.history.push("/login")
        window.location.reload();
    }

    render(){
        if (this.state.currentUser) {
            return (
                <>
                    <Navbar>
                        <Navbar.Header style={{width: 100, paddingTop: 18, paddingBottom: 18, paddingLeft: 20, fontWeight: 800, fontSize: 18}}>
                            <a href='https://admin.peai.co.za/' target='_blank' rel='noreferrer'>PEAI</a>
                        </Navbar.Header>
                            <Navbar.Body>
                                <Nav>
                                    <Nav.Item icon={<Icon icon="home"/>} href="/"> Home</Nav.Item>
                                    <Nav.Item icon={<Icon icon="user-plus"/>} href="/add-member"> Add Member</Nav.Item>
                                    <Nav.Item icon={<Icon icon="plus"/>} href="/add-newsletter"> Add Newsletter</Nav.Item>
                                </Nav>
                                <Nav pullRight>
                                    <Nav.Item onSelect={this.logOut} icon={<Icon icon="sign-out"/>}> Log Out</Nav.Item>
                                </Nav>
                            </Navbar.Body>
                    </Navbar>
                </>
            )
        } else {
            return (
                <>
                    <Navbar>
                        <Navbar.Header style={{width: 100, paddingTop: 18, paddingBottom: 18, paddingLeft: 20, fontWeight: 800, fontSize: 18}}>
                            <a href='https://admin.peai.co.za/' target='_blank' rel='noreferrer'>PEAI</a>
                        </Navbar.Header>
                            <Navbar.Body>
                                <Nav pullRight>
                                    <Nav.Item onSelect={this.logOut} icon={<Icon icon="sign-out"/>}> Login</Nav.Item>
                                </Nav>
                            </Navbar.Body>
                    </Navbar>
                </>
            )
        }
    }
}

export default NavbarComponent;