import axios from "axios";

const API_URL = "https://api.peai.co.za/api/auth";

class AuthService {
    login(memberNo, password) {
        return axios
            .post(API_URL + "/login", {
                memberNo,
                password
            })
            .then(res => {
                if (res.data.memberNo) {
                    localStorage.setItem("user", JSON.stringify(res.data));
                }
                return res.data;
            })
    }

    logout() {
        localStorage.removeItem("user");
    }

    getUsers() {
        return axios.get(API_URL + "/users");
    }

    register(name, surname, memberNo, password, email, media ) {
        return axios.post(API_URL + "/add", {
            name,
            surname,
            memberNo,
            password,
            email,
            media
        })
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem("user"));
    }

    deleteUser(id) {
        return axios.delete(API_URL + `/delete/${id}`);
    }

    addCertificate(id, media) {
        return axios.post(API_URL + `/certificate`, {
            id,
            media
        })
    }

    addNewsletter(id, media){
        return axios.post(API_URL + '/newsletter', {
            id,
            media
        })
    }

    updateUser(id, data) {
        const name = data.name;
        const surname = data.surname;
        const memberNo = data.memberNo;
        const email = data.email;
        return axios.post(API_URL + `/update/${id}`, {
            name,
            surname,
            memberNo,
            email
        })
    }

    getUser(id) {
        return axios.get(API_URL + `/user/${id}`);
    }
}

export default new AuthService();
