import React, { Component } from "react";

// Components
import LayoutComponent from "../global/layout.component";
import NavbarComponent from "../global/navbar.component";
import HeaderComponent from "../global/header.component";
import FooterComponent from "../global/footer.component";
import ContentComponent from "../global/content.component";
import AddComponent from "../subcomponents/add-member/add.component";

export default class AddMemberComponent extends Component {
    constructor(props) {
        super(props);

        // State
    }

    render() {
        return (
            <LayoutComponent>
                <HeaderComponent>
                    <NavbarComponent history={this.props.history} />
                </HeaderComponent>
                <ContentComponent>
                    <AddComponent />
                </ContentComponent>
                <FooterComponent />
            </LayoutComponent>
        )
    }
}