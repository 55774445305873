import React, {Component} from 'react';
import {Header} from "rsuite";

class HeaderComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            children: props.children
        }
    }

    render() {
        const { children } = this.state;
        return (
            <>
                <Header>
                    {children}
                </Header>
            </>
        );
    }
}

export default HeaderComponent;