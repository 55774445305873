import React, {Component} from 'react';
import {Container} from "rsuite";

class LayoutComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            children: props.children,
            title: props.title
        }
    }

    render() {
        const { children } = this.state;
        return (
            <>
                <Container>
                    {children}
                </Container>
            </>
        );
    }
}

export default LayoutComponent;
