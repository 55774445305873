import React, { Component } from "react";
import {Alert, Button, Modal, Placeholder, Table} from 'rsuite';
import fakeData from './data.json'
import ActionCell from './cells/ActionCell.component';
import EditCell from './cells/EditCell.component';
import AuthService from '../../../services/auth.service';

const { Column, HeaderCell, Cell } = Table;


class AllUsersComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
          data: [],
          originData: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleEditState = this.handleEditState.bind(this);
      }

      async componentDidMount() {
        await AuthService.getUsers().then(data => {
          this.setState({
            data: data.data,
            originData: data.data
          })
        })
      }

      handleChange(id, key, value) {
        const nextData = Object.assign([], this.state.data);
        nextData.find(item => item.id === id)[key] = value;
        this.setState({
          data: nextData
        });
      }

      handleEditState(id) {
      const nextData = Object.assign([], this.state.data);
      const activeItem = nextData.find(item => item.id === id);
      const old = this.state.originData.find(item => item.id === id);
      activeItem.status = activeItem.status ? null : 'EDIT';
      // Update data in the database here
      if (activeItem.status === null) {
        if ((activeItem === old)) {
          AuthService.updateUser(id, {name: activeItem.name, surname: activeItem.surname, memberNo: activeItem.memberNo, email: activeItem.email, role: activeItem.role}).then(() => {
            Alert.success("Member updated!");
            this.setState({
              originData: nextData
            })
          }).catch(err => {
            const resMessage =
                (err.response &&
                    err.response.data &&
                    err.response.data.message) ||
                err.message ||
                err.toString();

            Alert.error(resMessage);
          })
        }
        }

      this.setState({
        data: nextData
      });
      }

      render() {
        if (this.state.data.length > 0) {
          return (
              <div className='users-table'>
                <Table
                    hover={true}
                    data={this.state.data}
                    minHeight={512}
                    autoHeight={true}
                >
                  <Column width={200} align="center" fixed>
                    <HeaderCell>Member Number</HeaderCell>
                    <EditCell dataKey="memberNo" onChange={this.handleChange}/>
                  </Column>

                  <Column width={200} fixed>
                    <HeaderCell>Name</HeaderCell>
                    <EditCell dataKey="name" onChange={this.handleChange}/>
                  </Column>

                  <Column width={200}>
                    <HeaderCell>Surname</HeaderCell>
                    <EditCell dataKey="surname" onChange={this.handleChange}/>
                  </Column>

                  <Column width={200}>
                    <HeaderCell>Email</HeaderCell>
                    <EditCell dataKey="email" onChange={this.handleChange}/>
                  </Column>

                  <Column width={400} fixed="right">
                    <HeaderCell>Action</HeaderCell>
                    <ActionCell dataKey="id" onClick={this.handleEditState} />
                  </Column>

                </Table>
              </div>
          );
        } else {
          return (
              <Placeholder.Grid rows={4} columns={5} active />
          )
        }

      }
}

export default AllUsersComponent;