import React, {Component} from 'react';
import {Content} from "rsuite";

class ContentComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            children: props.children,
            classname: props.classname
        }
    }

    render() {
        const { children, classname } = this.state;
        return (
            <>
                <Content className={classname} >
                    {children}
                </Content>
            </>
        );
    }
}

export default ContentComponent;