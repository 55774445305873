import React, { Component } from "react";
import {Switch, Route, BrowserRouter, Redirect} from "react-router-dom";

import 'rsuite/lib/styles/themes/default/index.less';
import './App.css'

// Services
import AuthService from './services/auth.service';

// Components
import DashboardComponent from "./components/pages/dashboard.component";
import AddMemberComponent from "./components/pages/addmember.component";
import LoginComponent from "./components/pages/login.component";
import AddCertificatePageComponent from "./components/pages/addCertificate.component";
import UserProfilePageComponent from "./components/pages/userProfile.component";
import AddNewsletterPageComponent from "./components/pages/addNewsletter.component";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: undefined,
            isAdmin: false
        }
    }

    componentWillMount() {
        // Get user here
        const user = AuthService.getCurrentUser();

        if (user) {
            this.setState({
                user: user,
                isAdmin: user.roles.includes("ROLE_ADMIN")
            })
        }
    }

    render() {
        if (this.state.isAdmin && this.state.user) {
            return (
                <BrowserRouter>
                    <Switch>
                        <Route exact path={["/", "/dashboard"]} component={DashboardComponent} />
                        <Route exact path={["/add-member"]} component={AddMemberComponent} />
                        <Route path={"/add-certificate/:id"} component={AddCertificatePageComponent} />
                        <Route path={"/add-newsletter"} component={AddNewsletterPageComponent} />
                        <Route path={"/user/:id"} component={UserProfilePageComponent} />
                        <Route exact path={["/login"]} >
                            <Redirect to={"/"} />
                        </Route>
                    </Switch>
                </BrowserRouter>
            )
        } else {
            return (
                <BrowserRouter>
                    <Switch>
                        <Route exact path={["/", "/dashboard"]} >
                            <Redirect to={"/login"} />
                        </Route>
                        <Route exact path={["/add-member"]} >
                            <Redirect to={"/login"} />
                        </Route>
                        <Route path={"/add-certificate/:id"} >
                            <Redirect to={"/login"} />
                        </Route>
                        <Route path={"/add-newsletter"} >
                            <Redirect to={"/login"} />
                        </Route>
                        <Route exact path={["/login"]} component={LoginComponent} />
                    </Switch>
                </BrowserRouter>
            )
        }
    }
}

export default App;