import React, {Component} from 'react';
import {
    Button,
    ButtonToolbar,
    FormControl,
    FormGroup,
    HelpBlock,
    Loader,
    Schema,
    Notification,
    Form, Alert
} from "rsuite";

import AuthService from '../../../services/auth.service';
import {Redirect} from "react-router-dom";

// Schema
const { StringType } = Schema.Types;
const model = Schema.Model({
    memberNo: StringType().isRequired('This field is required.'),
    password: StringType().isRequired('This field is required.')
});

class TextField extends React.PureComponent {
    render() {
        const { name, label, accepter, ...props } = this.props;
        return (
            <FormGroup>
                {/*<ControlLabel>{label} </ControlLabel>*/}
                <FormControl name={name} accepter={accepter} {...props} />
                <HelpBlock>Required</HelpBlock>
            </FormGroup>
        );
    }
}

class LoginFormComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            checkTrigger: 'change',
            formValue: {},
            formError: {},
            loading: false,
            authenticated: false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit() {
        const { formValue } = this.state;
        if (!this.form.check()) {
            Notification['error']({
                title: "Invalid Inputs",
                description: <p>Please provide both a Member Number and password.</p>
            });
        }

        if (this.form.check()) {
            this.setState({
                loading: true
            });

             AuthService.login(formValue.memberNo, formValue.password)
                 .then((data) => {
                         this.setState({
                             authenticated: true
                         });
                         window.location.reload();
                     },
                     error => {
                         const resMessage =
                             (error.response &&
                                 error.response.data &&
                                 error.response.data.message) ||
                             error.message ||
                             error.toString();

                         this.setState({
                             loading: false
                         });
                         Alert.error(resMessage);
                     });
        }
    }

    render() {
        const { formValue, authenticated } = this.state;
        if (authenticated) {
            return (
                <Redirect to={"/"} />
            );
        } else {
            return (
                <>
                    <Form
                        fluid
                        ref={ref => (this.form = ref)}
                        onChange={formValue => {
                            this.setState({ formValue });
                        }}
                        onCheck={formError => {
                            this.setState({ formError });
                        }}
                        formValue={formValue}
                        model={model}
                    >
                        <TextField name="memberNo" placeholder="Member Number" />
                        <TextField name="password" placeholder="Password" type="password" />
                        <FormGroup>
                            <ButtonToolbar>
                                <Button appearance="primary" onClick={this.handleSubmit} disabled={this.state.loading}>
                                    {this.state.loading ? (
                                        <Loader content="Logging in..." />
                                    ) : (<span>Log in</span>)}
                                </Button>
                            </ButtonToolbar>
                        </FormGroup>
                    </Form>
                </>
            );
        }
    }
}

export default LoginFormComponent;